@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'San Francisco', 'Frutiger', 'Univers',
    'Helvetica Neue', 'Helvetica', sans-serif;
  text-rendering: optimizeLegibility;
}
body {
  min-width: 900px;
  font-size: 12px;
}

/******************************************************************************/

/* MicrobiomeDB specific breakpoints */

.HeaderNav-Branding h1.HeaderNav-Title {
  font-size: 3.3em !important;
}

@media (min-width: 1100px) {
  .HeaderNav-Branding h1.HeaderNav-Title {
    font-size: 4em !important;
  }
}

@media (max-width: 1200px) {
  .HeaderNav-Sticky .SiteMenu {
    margin: 5px auto !important;
  }
  .HeaderNav-Sticky .SiteMenuItem {
    font-size: 16px !important;
  }
  .HeaderNav-Sticky .UserMenu {
    margin: auto !important;
  }
}

/******************************************************************************/
.StudyMenuItem-RecordLink.DS_all {
  font-weight: bold;
}

/* Cross study card */
.StudyCard__DS_a4a0757110 .StudyCard-DetailsLink,
.StudyCard__DS_a4a0757110 .StudyCard-Download {
  visibility: hidden;
}
.StudyCard__DS_a4a0757110 .StudyCard-Stripe {
  background-color: #dd314e;
  color: white;
}

.StudyMenuItem-RecordLink.DS_a4a0757110 {
  pointer-events: none;
}

/******************************************************************************/

div.analysis-menu-tab-pane li.wdk-tooltip {
  width: 109px;
  height: 153px;
}

/* in AllSites.css is none so it does not affect other sites */
div.step-analysis-usernotes {
  display: block;
}

/* =========================================================================  */
/* Static Content  page - this should be moved to ebrc because it is a subset */
/*  of what clienpi has, though  genomic sites might have a diff style.       */
/* =========================================================================  */

#ce-static-content {
  padding: 0 3em;
}
div#ce-static-content summary {
  color: #069;
}
div#ce-static-content li .fa {
  color: #333;
  margin-right: 0.5em;
}
div#ce-static-content li {
  font-size: 130%;
  margin: 1em 0;
  list-style: none;
}
div#ce-static-content h1#news ~ section li {
  list-style: disc;
  margin-left: 1em;
}
div#ce-static-content h1#news ~ h2 {
  color: maroon;
}

#FAQ ~ div li p,
#FAQ ~ div li ol li {
  font-size: 90%;
  padding-left: 1em;
  list-style: decimal;
}
#FAQ ~ div li p span.indent {
  padding-left: 2em;
}
#FAQ ~ div li li {
  font-size: 90%;
  padding-left: 1em;
  list-style: disc;
}

/* for now, don't offer closeable banners in MicrobiomeDB */
.eupathdb-Announcement button.link {
  display: none;
}
