.wdk-Showcase {
  .ImageCard {
    &-Image {
      flex: 0 0 auto;
      min-height: 150px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      box-shadow: inset 0 -20px 25px rgba(12, 71, 90, 0.2);
    }
    &-Title {
      flex: 1;
      text-align: left;
      padding: 5px 10px 10px;
      h2,
      h3 {
        text-align: left;
        margin: 5px 0;
        padding: 0;
        font-weight: 300;
        b {
          font-weight: 700;
        }
      }
      p {
        margin: 10px 0 5px 0;
      }
    }
    a.ImageCard-Footer {
      flex: 0 0 auto;
      display: block;
      background-color: rgb(15, 89, 112);
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.9em;
      padding: 7px;
      &:hover,
      &:active {
        background-color: rgb(12, 71, 90);
        text-decoration: none;
      }
    }
  }
}
